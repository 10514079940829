import React from "react";
import {withNamespaces} from "react-i18next";
import {Col, Row} from "reactstrap";

const Educator = (props) => {

    const { educator, size, index } = props;

    return (
        <Row className={"align-items-center" + (size === (index + 1) ? '' : ' mb-4') }>
            <Col xs={4} sm={3}>
                <img className="rounded-circle" src={educator.image} width="100%"/>
            </Col>
            <Col xs={8} sm={9}>
                <h3>{ educator.name }</h3>
                <p className="text-primary mb-0">Educator</p>
            </Col>
        </Row>
    );
}

export default withNamespaces()(Educator);
