import React, {Component, useEffect, useState} from "react";
import {Container, Row, Col, Card, CardBody} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {axiosApi} from "../../helpers/Api";
import CourseCard from "../../components/Course";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";

const Dashboard = () => {

    const {user} = useSelector((state) => state.user);
    const [courses, setCourses] = useState([]);

    let breadcrumbItems = [
        { title : "Master", link : "/" },
        { title : "My Courses", link : "#" },
    ];

    const getCourses = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/courses/my/' + user.id)
            .then(response => setCourses(response.data))
            .catch(error => console.error(error));
    }

    useEffect(() => {
        getCourses();
    }, []);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="My Courses" breadcrumbItems={breadcrumbItems} />

                    <Row>
                        {
                            courses.map(course => (
                                <Col sm={3} md={4} className="d-flex align-items-stretch">
                                    <CourseCard course={course} />
                                </Col>
                            ))
                        }
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Dashboard;
