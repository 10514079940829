import {axiosApi, get, post} from "../helpers/Api";
import authHeader from "../helpers/Api/auth_header";

/**
 * @desc Method to login in the app.
 * @param email
 * @param password
 * @returns {Promise<unknown | string>}
 */
const login = (email, password) => {
    return axiosApi.post('api/login', {email: email, password: password, client: 'mindpro'})
        .then(response => {
            localStorage.setItem('auth_token', response.data.access_token);
            return getUser();
        })
        .catch(error => {
            let message = '';

            if (error.response) {
                message = error.response.data.message;
            } else if (error.request) {
                message = 'Server error: ' + error.request;
            } else {
                message = error.message;
            }

            return {
                error: message
            }
        });
}

const logout = () => {
    return get('api/logout', {headers: authHeader()})
        .then(response => {
            localStorage.removeItem("auth_token");
            return response;
        })
        .catch(error => console.log(error));
}

const getUser = () => {
    return get('api/me', {headers: authHeader()})
        .then(response => {

            if (response === 'GET FAILED') {
                return 'UNAUTHENTICATED'
            }

            return response.user;
        })
}

const authService = {
    login,
    logout,
    getUser
}

export default authService
