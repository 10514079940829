import React, {useState} from "react";
import {Button} from "reactstrap";
import {withNamespaces} from "react-i18next";

const ToggleButton = ({ onChange, text }) => {

    const [selected, setselected] = useState(false);
    const toggle = () => {
        onChange();
        setselected(!selected);
    };

    return (
        <Button onClick={toggle} style={{ marginRight: "10px", marginTop: "10px" }} className={'btn-rounded ' + (selected ? 'fw-bold' : '')} outline={!selected} color="primary">
            {text} { selected ? <i className="fa fa-times"/> : <></>}
        </Button>
    )
}

export default withNamespaces()(ToggleButton);
