import React, {useEffect, useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Col, Container, FormGroup, Input, Label, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Lesson from "./components/Lesson";
import {axiosApi} from "../../helpers/Api";
import ToggleButton from "../../components/Common/ToggleButton";
import Pagination from "../../components/Common/Pagination";

const RecordedLessons = (props) => {

    const [title, setTitle] = useState(null);
    const [educator, setEducator] = useState(null);
    const [category, setCategory] = useState(null);
    const [tag, setTag] = useState(null);

    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [pages, setPages] = useState([]);
    const [educators, setEducators] = useState([]);

    let breadcrumbItems = [
        {title: "Master", link: "/"},
        {title: "Recorded Lessons", link: "#"},
    ];

    const toggleCategory = (cat) => {
        let tmp = category ? [...category] : [];

        if (!tmp.find(element => element === cat.id)) tmp.push(cat.id);
        else tmp.splice(categories.indexOf(cat.id, 1))

        setCategory(tmp);
    }
    
    const toggleTag = (t) => {
        let tmp = tag ? [...tag] : [];

        if (!tmp.find(element => element === t.name)) tmp.push(t.name);
        else tmp.splice(categories.indexOf(t.name, 1))

        setTag(tmp);
    }

    const getEducators = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/educators')
            .then(response => {
                setEducators(response.data);
            })
            .catch(error => console.log(error));
    }

    const getLessons = async (page = null) => {
        const url = page === null ? process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/recorded-lessons' : page;
        await axiosApi.post(url, {
            title: title,
            educator: educator,
            categories: category,
            tags: tag ? tag.toString() : tag
        })
            .then(response => {
                setLessons(response.data.data);
                setPages(response.data.links);
            })
            .catch(error => console.error(error));
    }

    const getCategories = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/recorded-lessons/categories')
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => console.error(error));
    }

    const getTags = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/recorded-lessons/tags')
            .then(response => {
                setTags(response.data);
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        getEducators();
        getLessons();
        getCategories();
        getTags();
    }, []);


    useEffect(() => {
        if (title && title.length > 4) getLessons();
        if (title === '') getLessons();
    }, [title]);

    useEffect(() => {
        if (educator) getLessons();
        if (educator === '') getLessons();
    }, [educator]);

    useEffect(() => {
        if (category && category.length) getLessons();
        if (category && category.length === 0) getLessons();
    }, [category]);

    useEffect(() => {
        if (tag && tag.length) getLessons();
        if (tag && tag.length === 0) getLessons();
    }, [tag])

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Recorded Lessons" breadcrumbItems={breadcrumbItems}/>
                <br/>
                <h2 className="text-muted"><i className="fa fa-search"/> Filter Lessons</h2>
                <hr/>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>{props.t('Title')}</Label>
                            <Input placeholder="Filter by title..." type="text" value={title}
                                   onChange={(e) => setTitle(e.target.value)}/>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>{props.t('Educator')}</Label>
                            <Input placeholder="Filter by educator..." type="select" value={educator}
                                   onChange={(e) => setEducator(e.target.value)}>
                                <option></option>
                                {
                                    educators.map((educator, index) => (
                                        <option key={index} value={educator.id}>{educator.name}</option>
                                    ))
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <p className="text-muted">Categories</p>
                    </Col>
                    <Col md={12}>
                        {
                            categories.map((category) => <ToggleButton text={category.name}
                                                                       onChange={() => toggleCategory(category)}/>)
                        }
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col md={12}>
                        <p className="text-muted">Tags </p>
                    </Col>
                    <Col md={12}>
                        {
                            tags.map((tag) => <ToggleButton text={tag.name} onChange={() => toggleTag(tag)}/>)
                        }
                    </Col>
                </Row>
                <br/>
                <Row>
                    {
                        lessons.map((lesson) => (
                            <Col md={4} className="d-flex align-items-stretch">
                                <Lesson lesson={lesson}/>
                            </Col>
                        ))
                    }
                </Row>
                <Row className="mt-4">
                    <Col md={12}>
                        <Pagination pages={pages} getMethod={getLessons}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default withNamespaces()(RecordedLessons);
