import React, {Component, useEffect, useState} from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from 'react-i18next';

import {useSelector} from "react-redux";
import {axiosApi} from "../../../helpers/Api";
import {Badge} from "reactstrap";

const SidebarContent = (props) => {

    const {user} = useSelector((state) => state.user);

    const [masterclass, setMasterclass] = useState(false);

    const getMasterclass = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/masterclass/live-now')
            .then(response => setMasterclass(response.data))
            .catch(error => console.error(error));
    }

    const initMenu = () => {
        new MetisMenu("#side-menu");

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }

    const activateParentDropdown = (item) => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    useEffect(() => {
        initMenu();
        getMasterclass();
    }, []);


    return (
        <>
            <div id="sidebar-menu">

                <ul className="metismenu list-unstyled" id="side-menu">
                    <li className="menu-title">{props.t('Menu')}</li>

                    <li>
                        <Link to="/dashboard" className="waves-effect">
                            <i className="fas fa-star"></i>
                            <span className="ms-1">{props.t('My Courses')}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/courses" className="waves-effect">
                            <i className="fas fa-chalkboard-teacher"></i>
                            <span className="ms-1">{props.t('Courses')}</span>
                        </Link>
                    </li>
                    {
                        !user.expired ?
                            <>
                                <li>
                                    <Link to="/masterclass" className="waves-effect">
                                        <i className="fas fa-tv"/><span className="ms-1">{props.t('MasterClass')}</span> {'  '}
                                        {
                                            masterclass ? <span style={{ marginLeft: '15px' }} className="py-0 px-2 btn btn-danger btn-rounded">• Live</span> : <></>
                                        }
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/recorded-lessons" className="waves-effect">
                                        <i className="fas fa-play-circle"></i>
                                        <span className="ms-1">{props.t('Recorded Lessons')}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/signals" className="waves-effect">
                                        <i className="fas fa-lightbulb"></i>
                                        <span className="ms-1">{props.t('Signals')}</span>
                                    </Link>
                                </li>
                            </>
                            : <></>
                    }
                </ul>
            </div>
        </>
    )
}

export default withRouter(withNamespaces()(SidebarContent));
