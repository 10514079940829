import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Col, Row} from "reactstrap";

const Question = (props) => {

    const {question} = props;

    const [userAnswer, setUserAnswer] = useState(null);
    const [status, setStatus] = useState('pending');

    const evaluate = (answer) => {
        if (!userAnswer) {
            setUserAnswer(answer.id);

            if (answer.correct) {
                setStatus('correct');
            } else {
                setStatus('incorrect');
            }

            props.next(answer.correct);
        }
    }

    return (
        <>
            <h3>{question.question}</h3>
            <br/>
            <Row>
                {
                    question.answers.map((answer) => (
                        <Col className="mb-3" md={12}>
                            <Button onClick={() => evaluate(answer)} style={{width: "100%"}}
                                    outline={ userAnswer !== answer.id }
                                    color={  userAnswer === answer.id ? (status === 'correct' ? 'success' : 'danger') : 'primary' }>
                                {answer.answer}
                            </Button>
                        </Col>
                    ))
                }
            </Row>
        </>
    )
}

export default withNamespaces()(Question);
