import React, {useEffect, useState} from 'react';
import {withNamespaces} from "react-i18next";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {axiosApi} from "../../../../helpers/Api";
import {formatDate} from "../../../../utils/format";
import {useSelector} from "react-redux";

const PrivateMentorings = (props) => {

    const {user} = useSelector((state) => state.user);
    const [mentorings, setMentorings] = useState([]);

    /**
     * @desc Method to get the mentorings.
     * @returns {Promise<void>}
     */
    const getMentorings = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/private-mentorings/course/today/' + props.course.id)
            .then(response => setMentorings(response.data))
            .catch(error => console.log(error));
    }

    useEffect(() => {
        getMentorings();
    }, []);

    return (
        <Card>
            <CardBody>
                <h4 className="text-primary"><i className="fa fa-chalkboard"/> {'  '}
                    Private Mentorings {
                        user.subscription_id !== 5 ? <small className="text-secondary">For Platinum</small> : <></>
                    }
                </h4>
                <hr/>

                {
                    user.subscription_id === 5 ?
                        !mentorings.length ?
                            <Row className="align-items-center">
                                <Col md={12}>
                                    <h4 className="text-primary">No mentoring scheduled for today</h4>
                                    <p>When they become available you will find them here...</p>
                                </Col>
                            </Row> :
                            mentorings.map((mentoring, index) => (
                                <Row className="align-items-center">
                                    <Col md={9}>
                                        <p style={{fontSize: '20px'}}
                                           className="text-primary fw-bold mb-0">{mentoring.title}</p>
                                        <p>- {mentoring.educator.name}</p>
                                    </Col>
                                    <Col md={3}>
                                        <a href={mentoring.link} rel="noreferrer" target="_blank"
                                           className="btn btn-primary btn-rounded">Ingresar</a>
                                    </Col>
                                </Row>
                            )) :
                        <Row className="align-items-center">
                            <Col md={12}>
                                <h4 className="text-primary">Do you want private mentoring?</h4>
                                <p>Private mentoring is for our platinum members. Become platinum and get access to more
                                    content.</p>
                            </Col>
                        </Row>
                }
            </CardBody>
        </Card>
    )
}

export default withNamespaces()(PrivateMentorings);
