import {withNamespaces} from "react-i18next";
import {Card, CardBody, Col, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import {axiosApi} from "../../../../helpers/Api";

const Resources = (props) => {

    const [resources, setResources] = useState([]);

    const getResources = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/lesson/resources/' + props.lesson.id)
            .then((response) => {
                setResources(response.data);
            })
            .catch((error) => console.error(error));
    }

    useEffect(() => {
        getResources();
    }, []);

    return (
        <Card style={{height: "100%"}}>
            <CardBody>
                <h4 className="text-primary"><i className="fa fa-file-alt"/> Resources</h4>
                <hr/>
                {
                    resources.map((resource) => (
                        <Row className="align-items-center">
                            <Col>
                                <h5 className="text-secondary mb-0"> <i className="fa fa-file text-primary"/> { resource.title }</h5>
                            </Col>
                            <Col style={{ textAlign: "right" }}>
                                <a href={resource.url} target="_blank" className="btn btn-primary btn-rounded py-1"><i className="fa fa-download"/> </a>
                            </Col>
                        </Row>
                    ))
                }
            </CardBody>
        </Card>
    )
}

export default withNamespaces()(Resources);
