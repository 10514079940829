import React, {useEffect, useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Col, Container, FormGroup, Input, InputGroup, Label, Row} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CourseCard from "../../components/Course";
import {axiosApi} from "../../helpers/Api";

const Courses = (props) => {

    const [name, setName] = useState('');

    const [courses, setCourses] = useState([]);

    let breadcrumbItems = [
        { title : "Mindpro", link : "/" },
        { title : "Courses", link : "#" },
    ];

    const getCourses = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/courses')
            .then(response => setCourses(response.data))
            .catch(error => console.error(error));
    }

    const searchCourses = async () => {
        if (name) {
            await axiosApi.post(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/courses/get', { name: name })
                .then(response => setCourses(response.data))
                .catch(error => console.log(error));
        } else {
            getCourses();
        }
    }

    useEffect(() => {
        getCourses()
    }, []);



    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Courses" breadcrumbItems={breadcrumbItems} />

                    <Row>
                        <Col md={12}>
                            <InputGroup>
                                <Input placeholder="Type for search a course..." value={name} onChange={(e) => setName(e.target.value)} />
                                <Button onClick={searchCourses} color="primary">
                                    <i className="fa fa-search"/> Search
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        {
                            courses.map(course => (
                                <Col sm={3} md={4} className="d-flex align-items-stretch">
                                    <CourseCard course={course} />
                                </Col>
                            ))
                        }
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default withNamespaces()(Courses);
