import React, {useState} from "react";
import {withNamespaces} from "react-i18next";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Row} from "reactstrap";
import {axiosApi} from "../../../../helpers/Api";
import Question from "./Components/Question";
import winner from "../../../../assets/images/winner.png";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

const Quiz = (props) => {

    const {user} = useSelector((state) => state.user);
    const history = useHistory();
    const [quiz, setQuiz] = useState({});
    const [loading, setLoading] = useState(true);

    const [quizStart, setQuizStart] = useState(false);
    const [page, setPage] = useState(0);

    const [answers, setAnswers] = useState([]);

    const getQuiz = async () => {

        setQuizStart(false);
        setPage(0);
        setAnswers([]);

        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/quiz/' + props.lesson.id)
            .then(response => {
                setQuiz(response.data);
                setLoading(false);
            })
            .catch(error => console.error(error));
    }

    const nextClass = async () => {
        let data = {
            user_id: user.id,
            lesson_id: props.lesson.id,
            next_lesson_id: props.lesson.next_lesson.id
        }

        await axiosApi.post(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/quiz/next', data)
            .then(response => {
                props.toggle();
                window.location.href = '/lesson/' + props.lesson.next_lesson.id;
            })
            .catch(error => console.error(error));
    }

    const nextQuestion = async (answer) => {
        if (answer) {
            let temp = [...answers, answer];
            setAnswers(temp);
        }
        await delay(1);
        setPage(page + 1);
    }

    const delay = (n) => new Promise( r => setTimeout(r, n*1000));

    const percent = () => {
        return (answers.length * 100)/quiz.questions.length;
    }

    return (
        <Modal onOpened={getQuiz} centered toggle={props.toggle} isOpen={props.isOpen}>
            {
                loading ? <></> :
                    <>
                        <ModalHeader charCode="Y" toggle={props.toggle}>
                            <span className="text-primary">Quiz</span>
                        </ModalHeader>
                        <ModalBody>
                            {
                                !quizStart ?
                                    <>
                                        <h2 className="fw-bold text-primary">Time to evaluate all that has been learned</h2>
                                        <br/>
                                        <h5 className="mb-3">Ten en cuenta: </h5>
                                        <ul>
                                            <li className="mb-2" >El quiz consta de <strong>{ quiz.questions.length } preguntas.</strong></li>
                                            <li className="mb-2" >No tiene limite de tiempo, tomalo con calma.</li>
                                            <li className="mb-2" >Puedes presentar el quiz <strong>tantas veces como quieras.</strong></li>
                                            <li className="mb-2" >Para aprobar necesitas responder <strong>correctamente mas del 70% de las preguntas.</strong></li>
                                        </ul>
                                        <br/>
                                        <Row className="justify-content-center" >
                                            <Col xs={4}>
                                                <Button onClick={() => setQuizStart(true)} color="primary" outline >Empezar <strong>Quiz</strong></Button>
                                            </Col>
                                        </Row>
                                    </> :
                                    (page) !== quiz.questions.length ?
                                    <>
                                        <Progress value={ ((page + 1) * 100) / quiz.questions.length } />
                                        <br/>
                                        <h4 className="text-secondary">Question  { page + 1 } <small>/{ quiz.questions.length }</small></h4>
                                        <hr/>
                                        {
                                            quiz.questions.map((question, index) => (
                                                page === index ? <Question next={nextQuestion} question={question} /> : <></>
                                            ))
                                        }
                                    </>
                                        :
                                        <div style={{ textAlign: "center" }}>
                                            {
                                                percent() > 70 ?
                                                    <img className="mb-4" src={winner} width="150px" /> : <></>
                                            }
                                            <h3 className="fw-bold text-primary">{ percent() > 70 ? 'Great!' : 'Too Bad!' }</h3>
                                            <h2 className={'fw-bold' + ( percent() > 70 ? ' text-success' : ' text-danger')}>{ percent() }% Score</h2>
                                            <h5 className="">{ percent() > 70 ? 'Quiz completed successfuly' : 'You did not answer enough questions correctly. Watch the class one more time and try again.' }</h5>
                                            <br/>
                                            {
                                                percent() > 70 ?
                                                    <>
                                                    {
                                                        props.lesson.next_lesson ? <Button onClick={nextClass} color="primary" >Go to Next Lesson</Button>: <></>
                                                    }
                                                    { '  '}<Button onClick={() => history.push('/course/' + props.lesson.level.course_id)} outline color="primary" >Go to Course</Button>
                                                    </> :
                                                    <>
                                                        <Button onClick={() => history.push('/course/' + props.lesson.level.course_id)} outline color="primary" >Go to Course</Button> {'  '}
                                                        <Button color="primary" onClick={props.toggle} >Close</Button>
                                                    </>
                                            }
                                        </div>
                            }
                        </ModalBody>
                    </>
            }
        </Modal>
    )
}

export default withNamespaces()(Quiz);
