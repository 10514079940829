import React, {Component, useEffect, useState} from "react";
import {Switch, BrowserRouter as Router, withRouter} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import css
import 'toastr/build/toastr.min.css'

// Import css
import "./assets/css/custom.css";
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {checkAuth} from "./features/userSlice";

import "jspdf/dist/polyfills.es.js";

const App = (props) => {

	const {user} = useSelector((state) => state.user);

	const [loading, setLoading] = useState(true);

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(checkAuth())
			.then(response => {
				setLoading(false)
			})
			.catch(error => {
				props.history.push('/login')
				console.log(error)
			})
	}, []);

	return (
		<>
			<Router>
				<Switch>
					{publicRoutes.map((route, idx) => (
						<AppRoute
							path={route.path}
							layout={NonAuthLayout}
							component={route.component}
							key={idx}
							isAuthProtected={false}
						/>
					))}

					{
						loading ?
							<div id="preloader">
								<div id="status">
									<div className="spinner">
										<i className="ri-loader-line spin-icon"/>
									</div>
								</div>
							</div> :
							authProtectedRoutes.map((route, idx) => (
								<AppRoute
									path={route.path}
									layout={VerticalLayout}
									component={route.component}
									key={idx}
									isAuthProtected={true}
								/>
							))
					}
				</Switch>
			</Router>
		</>
	)
}

export default withRouter(App);
