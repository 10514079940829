import jsPDF from "jspdf";
import certificateC from "../assets/images/certificates/certificate.jpg";
import certificateB from "../assets/images/certificates/certificate_two_educators.jpg";
import certificateA from "../assets/images/certificates/certificate_one_educator.jpg";
import moment from "moment";

export const createCertificate = (educators, user, level, course) => {
    return new Promise(resolve => {
        const doc = new jsPDF('landscape');

        const width = doc.internal.pageSize.getWidth()
        const height = doc.internal.pageSize.getHeight()

        let certificate = null;
        let signatures = [];
        let positions = [];

        if (educators.find(educator => educator.id === 1)) {
            certificate = certificateC;
        } else {
            if (educators.length > 1) {
                certificate = certificateB;

                positions = [
                    {x: width - 175, y: height - 55},
                    {x: 38, y: height - 55}
                ]
            } else {
                certificate = certificateA;

                positions = [
                    {x: 38, y: height - 55}
                ]
            }

            signatures = educators.map((educator) => educator.signature);
        }

        doc.addImage(certificate, 'JPEG', 0, 0, width, height);
        doc.setFontSize(30);
        doc.text(user.name, width / 2, height / 2, {align: 'center'});

        doc.setFontSize(20);
        doc.text(level.name, width / 2, height - 81, {align: 'center'});

        doc.setFontSize(20);
        doc.text(course.title, width / 2, height - 65, {align: 'center'});

        const date = moment().format('DD-MM-YYYY');
        doc.setFontSize(12);
        doc.text(date, width - 110, height - 13, {align: 'center'});

        doc.setFontSize(14);
        doc.setTextColor('#691ED8');
        doc.text(`${course.id}${level.id}${user.id}`, width - 38, height - 12.5, {align: 'center'});

        signatures.forEach((signature, index) => {
            doc.addImage(signature, 'PNG', positions[index].x, positions[index].y, 65, 22)
        });

        let username = user.name.toLowerCase().replace(/\s/g, '_');
        let courseTitle = course.title.toLowerCase().replace(/\s/g, '_');

        doc.save(`${username}_${level.name.toLowerCase()}_${courseTitle}_certificate`);

        setTimeout(() => {
            resolve('resolved');
        }, 2000)

    });
}

export const generateCertificate = (educators, user, level, course) => {
    const doc = new jsPDF('landscape');

    const width = doc.internal.pageSize.getWidth()
    const height = doc.internal.pageSize.getHeight()

    let certificate = null;
    let signatures = [];
    let positions = [];

    if (educators.find(educator => educator.id === 1)) {
        certificate = certificateC;
    } else {
        if (educators.length > 1) {
            certificate = certificateB;

            positions = [
                {x: width - 175, y: height - 55},
                {x: 38, y: height - 55}
            ]
        } else {
            certificate = certificateA;

            positions = [
                {x: 38, y: height - 55}
            ]
        }

        signatures = educators.map((educator) => educator.signature);
    }

    doc.addImage(certificate, 'JPEG', 0, 0, width, height);
    doc.setFontSize(30);
    doc.text(user.name, width / 2, height / 2, {align: 'center'});

    doc.setFontSize(20);
    doc.text(level.name, width / 2, height - 81, {align: 'center'});

    doc.setFontSize(20);
    doc.text(course.title, width / 2, height - 65, {align: 'center'});

    const date = 'date';
    doc.setFontSize(12);
    doc.text(date, width - 110, height - 13, {align: 'center'});

    doc.setFontSize(14);
    doc.setTextColor('#691ED8');
    doc.text('code', width - 38, height - 12.5, {align: 'center'});

    signatures.forEach((signature, index) => {
        doc.addImage(signature, 'PNG', positions[index].x, positions[index].y, 65, 22)
    });

    let username = user.name.toLowerCase().replace(/\s/g, '_');
    let courseTitle = course.title.toLowerCase().replace(/\s/g, '_');

    doc.save(`${username}_${level.name.toLowerCase()}_${courseTitle}_certificate`)
}
