import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

import Courses from "../pages/Courses";
import Course from "../pages/Course";
import Lesson from "../pages/Lesson";
import Masterclass from "../pages/Masterclass";
import RecordedLessons from "../pages/RecordedLessons";

const authProtectedRoutes = [

	{ path: "/dashboard", component: Dashboard },
	{ path: "/courses", component: Courses },
	{ path: "/course/:id", component: Course },
	{ path: "/lesson/:id", component: Lesson },
    { path: "/masterclass", component: Masterclass },
	{ path: "/recorded-lessons", component: RecordedLessons },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login }

];

export { authProtectedRoutes, publicRoutes };
