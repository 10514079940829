import React from "react";
import {withNamespaces} from "react-i18next";
import {Card, CardBody, CardFooter, Col, Row} from "reactstrap";
import '../styles/Lesson.css';
import {formatDate} from "../../../utils/format";

const Lesson = (props) => {

    const {lesson} = props;

    const open = () => {
        window.open(lesson.link, '_blank').focus();
    }

    return (
        <Card className="lesson-card" onClick={open}>
            <CardBody>
                <h4>{lesson.title}</h4>
            </CardBody>
            <CardFooter>
                <Row>
                    <hr/>
                    <Col md={6}>
                        <i className="fa fa-chalkboard-teacher text-primary"/> {'  '}{lesson.educators.map((educator, index) => lesson.educators.length === index + 1 ? educator.name : educator.name + ', ')}
                    </Col>
                    <Col md={6}>
                        <i className="fa fa-clock text-primary"/> {formatDate(lesson.date)}
                    </Col>
                </Row>
            </CardFooter>
        </Card>
    )
}

export default withNamespaces()(Lesson);
