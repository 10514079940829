import React from "react";
import {withNamespaces} from "react-i18next";
import {Card, CardBody, CardFooter, CardImg, Col, Row} from "reactstrap";
import './styles/index.css';
import {useHistory} from "react-router-dom";

const CourseCard = (props) => {

    const history = useHistory();

    const {course} = props;

    return (
        <Card className="course-card" onClick={ () => history.push('/course/' + course.id) }>
            <CardImg top src={course.cover} alt="Skote" />
            <CardBody>
                <h3 className="mb-2 mt-2">{ course.title }</h3>
                <p className="mb-3">- { course.educators.map((educator, index) => educator.name + (course.educators.length === (index + 1) ? '' : ', ')) }</p>
                <hr/>
                <p style={{ textAlign: 'justify' }}>
                    { course.description }
                </p>
            </CardBody>
            <CardFooter>
                <Row>
                    <hr/>
                    <Col xs={5}>
                        <i className="fa fa-bars text-primary" style={{ marginRight: "6px" }} />{ course.lessons } Lessons
                    </Col>
                    <Col xs={7}>
                        <i className="fa fa-clock text-primary" style={{ marginRight: "6px" }} />{ course.duration }
                    </Col>
                </Row>
            </CardFooter>
        </Card>
    );
}

export default withNamespaces()(CourseCard);
