import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import "./styles/index.css";
import Level from "./components/Level";
import { axiosApi } from "../../helpers/Api";
import { useParams } from "react-router-dom";
import Educator from "./components/Educator";
import { useSelector } from "react-redux";
import PrivateMentorings from "./components/PrivateMentorings";

const Course = (props) => {
  let { id } = useParams();
  const { user } = useSelector((state) => state.user);
  const [lessons, setLessons] = useState([]);

  const [course, setCourse] = useState({});
  const [loading, setLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);

  let breadcrumbItems = [
    { title: "Master", link: "/" },
    { title: "Courses", link: "/courses" },
    { title: "Course", link: "#" },
  ];

  const getCourse = async () => {
    await axiosApi
      .get(process.env.REACT_APP_MINDPRO_SERVICE_URL + "api/course/" + id)
      .then((response) => {
        setCourse(response.data);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  const subscribed = async () => {
    await axiosApi
      .post(
        process.env.REACT_APP_MINDPRO_SERVICE_URL + "api/course/subscribed",
        {
          user_id: user.id,
          course_id: course.id,
        }
      )
      .then((response) => {
        setIsSubscribed(response.data);
      })
      .catch((error) => console.error(error));
  };

  const userLessons = async () => {
    await axiosApi
      .post(
        process.env.REACT_APP_MINDPRO_SERVICE_URL + "api/course/user/lessons",
        {
          user_id: user.id,
          course_id: course.id,
        }
      )
      .then((response) => {
        setLessons(response.data);
      })
      .catch((error) => console.error(error));
  };

  const subscribe = async () => {
    await axiosApi
      .post(
        process.env.REACT_APP_MINDPRO_SERVICE_URL + "api/course/subscribe",
        {
          user_id: user.id,
          course_id: course.id,
        }
      )
      .then((response) => {
        subscribed();
      })
      .catch((error) => console.error(error));
  };

  const numberLessons = (levels) => {
    return levels
      .map((level) => level.lessons.length)
      .reduce((prev, curr) => prev + curr, 0);
  };

  useEffect(() => {
    getCourse();
  }, []);

  useEffect(() => {
    subscribed();
  }, [course]);

  useEffect(() => {
    userLessons();
  }, [isSubscribed]);

  return loading ? (
    <></>
  ) : (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Course" breadcrumbItems={breadcrumbItems} />

          <Row>
            <Card className="course-banner">
              <CardBody>
                <Row>
                  <Col className="col" md={6}>
                    <p>Curso: </p>
                    <h1>{course.title}</h1>
                    <p>{course.description}</p>
                    <br />
                    {isSubscribed ? (
                      <></>
                    ) : (
                      <Button onClick={subscribe} color="light" outline>
                        {" "}
                        <i className="fa fa-plus" /> Subscribe to this course.
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
          <br />
          <Row>
            <Col md={12}>
              <h3 className="text-primary">Course Content</h3>
              <p>
                {course.levels.length} Levels • {numberLessons(course.levels)}{" "}
                Lessons • {course.duration}
              </p>
            </Col>
            <Col md={7}>
              <Card>
                <CardBody>
                  <Level
                    level={course.levels[0]}
                    lessons={course.levels[0].lessons}
                    userLessons={lessons}
                    getCourse={getCourse}
                    getUserLessons={userLessons}
                    course={course}
                    educators={course.educators}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col md={5}>
              <Card>
                <CardBody>
                  <h4 className="text-primary">
                    <i className="fa fa-chalkboard-teacher" /> Educators
                  </h4>
                  <hr />
                  {course.educators.map((educator, index) => (
                    <Educator
                      educator={educator}
                      size={course.educators.length}
                      index={index}
                    />
                  ))}
                </CardBody>
              </Card>
              {!user.expired ? <PrivateMentorings course={course} /> : <></>}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withNamespaces()(Course);
