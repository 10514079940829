import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { axiosApi } from "../../helpers/Api";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import ReactPlayer from "react-player";
import "./styles/player.css";
import Quiz from "./Components/Quiz";
import { useSelector } from "react-redux";
import Resources from "./Components/Resources";

const Lesson = () => {
  let { id } = useParams();
  const history = useHistory();

  const { user } = useSelector((state) => state.user);

  const [lesson, setLesson] = useState({});
  const [loading, setLoading] = useState(true);

  const [quiz, setQuiz] = useState(false);
  const toggleQuiz = () => setQuiz(!quiz);

  let breadcrumbItems = [
    { title: "Master", link: "/" },
    { title: "Courses", link: "/courses" },
    { title: lesson ? lesson.title : "", link: "#" },
  ];

  const getLesson = async () => {
    await axiosApi
      .get(process.env.REACT_APP_MINDPRO_SERVICE_URL + "api/lesson/" + id)
      .then((response) => {
        setLesson(response.data);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  const nextLesson = async () => {
    let data = {
      user_id: user.id,
      lesson_id: lesson.id,
      next_lesson_id: lesson.next_lesson?.id,
    };

    await axiosApi
      .post(
        process.env.REACT_APP_MINDPRO_SERVICE_URL + "api/lesson/complete",
        data
      )
      .then((response) => {
        if (lesson.next_lesson)
          window.location.href = "/lesson/" + lesson.next_lesson.id;
        else window.location.href = `/course/${lesson.level.course_id}`;
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getLesson();
  }, []);

  return loading ? (
    <></>
  ) : (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={lesson.title} breadcrumbItems={breadcrumbItems} />

          <Row>
            <Col md={8}>
              <div className="player-wrapper">
                <ReactPlayer
                  url={lesson.video}
                  playing={false}
                  controls={true}
                  width="100%"
                />
              </div>
            </Col>
            <Col md={4} className="m-xs-4 m-0">
              <Resources lesson={lesson} />
            </Col>
            <Col md={12} className="mt-3">
              <Card>
                <CardBody>
                  <h4 className="text-primary">{lesson.title}</h4>
                  <hr />
                  <div dangerouslySetInnerHTML={{ __html: lesson.content }} />
                </CardBody>
              </Card>
            </Col>
            {lesson.test ? (
              <Col md={12}>
                <Card className="bg-mindpro">
                  <CardBody>
                    <Row className="align-items-center">
                      <Col xs={6}>
                        <h4 className="text-white mb-0">
                          <strong>Great,</strong> time to evaluate your
                          learning.
                        </h4>
                      </Col>
                      <Col style={{ textAlign: "right" }} xs={6}>
                        <Button
                          className="px-5"
                          color="primary"
                          onClick={toggleQuiz}
                        >
                          Take <strong>Quiz</strong>
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              <Col md={12} className="mb-4">
                <Button
                  style={{ width: "100%" }}
                  className="fw-bold px-5"
                  color="primary"
                  onClick={nextLesson}
                >
                  Go to Next Lesson
                </Button>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      <Quiz lesson={lesson} isOpen={quiz} toggle={toggleQuiz} />
    </>
  );
};

export default withNamespaces()(Lesson);
