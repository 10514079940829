import React, { useEffect } from "react";

// Redux
import { Link, withRouter } from "react-router-dom";

import {
  Row,
  Col,
  Input,
  Button,
  Container,
  Label,
  FormGroup,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

// import images
import logodark from "../../assets/images/logo/MINDPRO-2.png";

import { useDispatch } from "react-redux";
import { login } from "../../features/userSlice";

import toastr from "toastr";

const Login = (props) => {
  const dispatch = useDispatch();

  /**
   * Login method
   * @param event
   * @param values
   */
  const handleSubmit = (event, values) => {
    dispatch(login(values)).then((response) => {
      if (response.error?.message === "Rejected") {
        toastr.error(response.payload.error, "Error");
      } else {
        return props.history.push("/dashboard");
      }
    });
  };

  useEffect(() => {
    document.body.classList.add("auth-body-bg");
  }, []);

  useEffect(() => {
    return document.body.classList.remove("auth-body-bg");
  }, []);

  return (
    <>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} className="bg-dark">
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="logo">
                              <img src={logodark} width="80%" alt="logo" />
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-4 text-white">
                            Bienvenido!
                          </h4>
                          <p className="text-white">
                            Digita tus credenciales para ingresar.
                          </p>
                        </div>

                        <div className="p-2">
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={handleSubmit}
                          >
                            <FormGroup className="auth-form-group-custom mb-4">
                              <i className="ri-user-2-line auti-custom-input-icon"></i>
                              <Label htmlFor="username">Email</Label>
                              <AvField
                                name="email"
                                type="text"
                                className="form-control"
                                id="email"
                                placeholder="Ej: user@izeven.com"
                              />
                            </FormGroup>

                            <FormGroup className="auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon"></i>
                              <Label htmlFor="password">Contraseña</Label>
                              <AvField
                                name="password"
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Ingresa tu password"
                              />
                            </FormGroup>

                            <div className="form-check">
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                              />
                              <Label
                                className="form-check-label text-white"
                                htmlFor="customControlInline"
                              >
                                Recordarme
                              </Label>
                            </div>

                            <div className="mt-4 text-center">
                              <Button
                                color="primary"
                                className="w-md waves-effect waves-light"
                                type="submit"
                              >
                                Ingresar
                              </Button>
                            </div>

                            <div className="mt-4 text-center">
                              <a
                                href="https://app.izeven.com/forgot-password"
                                target="_blank"
                                className="text-white"
                              >
                                <i className="mdi mdi-lock me-1 text-white"></i>
                                Olvidaste tu contraseña?
                              </a>
                            </div>
                          </AvForm>
                        </div>

                        <div className="mt-5 text-center text-white">
                          <p>
                            © {new Date().getFullYear()}{" "}
                            <a
                              href="https://izeven.com/"
                              target="_blank"
                              className="text-white"
                            >
                              iZeven
                            </a>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="authentication-bg" />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withRouter(Login);
