import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Row,
  Table,
  Spinner,
} from "reactstrap";
import { useSelector } from "react-redux";
import { axiosApi } from "../../../helpers/Api";
import Quiz from "./Quiz";

import { createCertificate } from "../../../utils/certificates";

const Level = (props) => {
  const { user } = useSelector((state) => state.user);
  const { level, lessons, educators, course } = props;

  const history = useHistory();

  const [quiz, setQuiz] = useState(false);
  const [cert, setCert] = useState(false);

  const [modalQuiz, setModalQuiz] = useState(false);
  const toggleModalQuiz = () => setModalQuiz(!modalQuiz);

  const [open, setOpen] = useState(true);
  const toggle = () => setOpen(!open);

  const [loading, setLoading] = useState(false);

  const subscription = (sb) => {
    if (sb === 2) return "Bronze";
    if (sb === 3) return "Silver";
    if (sb === 4) return "Gold";
    if (sb === 5) return "Platinum";
  };

  const showQuiz = async () => {
    await axiosApi
      .post(process.env.REACT_APP_MINDPRO_SERVICE_URL + "api/level/show-quiz", {
        level_id: level.id,
        user_id: user.id,
      })
      .then((response) => {
        setQuiz(!!response.data.showQuiz);
        setCert(!!response.data.showCert);
      })
      .catch((error) => console.error(error));
  };

  const downloadCertificate = async () => {
    setLoading(true);
    setTimeout(() => {
      createCertificate(educators, user, level, course).then((response) =>
        setLoading(false)
      );
    }, 1000);
  };

  useEffect(() => {
    if (level) {
      showQuiz();
    }
  }, [level]);

  return (
    <>
      <Card className="mb-1 shadow-none">
        <CardBody>
          {level.min_subscription <= user.subscription_id ? (
            <>
              <Table responsive hover>
                <tbody>
                  {lessons.map((lesson, index) => (
                    <tr
                      className={
                        props.userLessons.includes(lesson.id)
                          ? "text-success"
                          : ""
                      }
                    >
                      <td style={{ width: "5%" }}>
                        <i
                          className={
                            "fa" +
                            (!props.userLessons.includes(lesson.id)
                              ? " fa-lock"
                              : " fa-unlock")
                          }
                        />
                      </td>
                      <td
                        onClick={
                          props.userLessons.includes(lesson.id)
                            ? () => history.push("/lesson/" + lesson.id)
                            : null
                        }
                        style={{
                          width: "60%",
                          cursor: props.userLessons.includes(lesson.id)
                            ? "pointer"
                            : "",
                        }}
                      >
                        {lesson.title}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {quiz && !cert ? (
                <Button
                  onClick={toggleModalQuiz}
                  className="btn-rounded fw-bold"
                  style={{ width: "100%" }}
                  color="primary"
                >
                  <i className="fa fa-edit" /> Level Quiz
                </Button>
              ) : (
                <></>
              )}
              {cert ? (
                <Button
                  disabled={loading}
                  onClick={downloadCertificate}
                  className="btn-rounded fw-bold"
                  style={{ width: "100%" }}
                  color="primary"
                  outline
                >
                  {loading ? (
                    <Spinner size="sm" />
                  ) : (
                    <i className="fa fa-medal" />
                  )}
                  {loading
                    ? "  Downloading Certificate..."
                    : "  Download Certificate"}
                </Button>
              ) : (
                <></>
              )}
              <Quiz
                level={level}
                isOpen={modalQuiz}
                toggle={toggleModalQuiz}
                getCourse={props.getCourse}
                getUserLessons={props.getUserLessons}
              />
            </>
          ) : (
            <Row className="justify-content-center">
              <Col className="text-center" md={12}>
                <h1>
                  <i className="fa fa-lock text-primary" />
                </h1>
                <h5>
                  {level.name} level is available from the subscription{" "}
                  <strong className="fw-bold text-primary">
                    {subscription(level.min_subscription)}
                  </strong>
                </h5>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default withNamespaces()(Level);
