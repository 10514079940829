import React from "react";
import {
    Button,
} from "reactstrap";

import { Link } from "react-router-dom";

// Import menuDropdown
import LanguageDropdown from "../../CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../../CommonForBoth/TopbarDropdown/ProfileMenu";

//Import i18n
import { withNamespaces } from "react-i18next";

//Import logo Images
import logosmdark from "../../../assets/images/logo/MINDPRO-IMAGOTIPO.png";
import logodark from "../../../assets/images/logo/MINDPRO-2.png";
import logosmlight from "../../../assets/images/logo/MINDPRO-IMAGOTIPO.png";
import logolight from "../../../assets/images/logo/MINDPRO-2.png";


const Header = (props) => {

    const toggleFullscreen = () => {
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    return (
        <>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">

                        <div className="navbar-brand-box">
                            <Link to="#" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logosmdark} alt="" width="100%" />
                                    </span>
                                <span className="logo-lg">
                                        <img src={logodark} alt="" width="100%" />
                                    </span>
                            </Link>

                            <Link to="#" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logosmlight} alt="" width="100%" />
                                    </span>
                                <span className="logo-lg">
                                        <img src={logolight} alt="" width="100%" />
                                    </span>
                            </Link>
                        </div>

                        <Button onClick={props.toggleMenuCallback} size="sm" color="none" type="button" className="px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn">
                            <i className="ri-menu-2-line align-middle"></i>
                        </Button>
                    </div>

                    <div className="d-flex">
                        <LanguageDropdown />
                        <div className="dropdown d-none d-lg-inline-block ms-1">
                            <Button color="none" type="button" className="header-item noti-icon waves-effect" onClick={toggleFullscreen}>
                                <i className="ri-fullscreen-line"></i>
                            </Button>
                        </div>

                        <ProfileMenu />
                    </div>
                </div>
            </header>
        </>
    )
}
export default withNamespaces()(Header);
