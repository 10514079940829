import React, {useEffect, useRef, useState} from "react";
import {withNamespaces} from "react-i18next";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Col, Container, Row} from "reactstrap";
import { isMobile } from "react-device-detect";
import './styles/masterclass.css';
import {axiosApi} from "../../helpers/Api";

const Masterclass = (props) => {

    const videoRef = useRef(null);

    const [masterclass, setMasterclass] = useState(null);

    let breadcrumbItems = [
        {title: "Master", link: "/"},
        {title: "Masterclass", link: "#"},
    ];

    const getMasterclass = async () => {
        await axiosApi.get(process.env.REACT_APP_MINDPRO_SERVICE_URL + 'api/masterclass')
            .then(response => {
                setMasterclass(response.data);
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        getMasterclass();
    }, [])

    return (
        <div className="page-content">
            <Container fluid>
                {
                    masterclass ?
                        <>
                            <Breadcrumbs title="Masterclass" breadcrumbItems={breadcrumbItems}/>
                            <h1 className="fw-bold text-secondary">{ masterclass.title }</h1>
                            <h4>By: {masterclass.educators.map((educator, index) => educator.name + (index === masterclass.educators.length -1 ? '' : ', ')  )}</h4>
                            <hr/>
                            <Row>
                                <Col ref={videoRef} md={7}>
                                    <div style={{padding: "56.25% 0 0 0", position: "relative"}}>
                                        <iframe src={masterclass.url} frameBorder="0"
                                                allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                                                style={{position: "absolute", top: "0", left: "0", width: "100%", height: "100%"}}/>
                                    </div>
                                </Col>
                                <Col md={5}>
                                    <div className="chat">
                                        <iframe src={masterclass.chat} width="100%" height="100%"
                                                frameBorder="0"></iframe>
                                    </div>
                                </Col>
                            </Row>
                        </> :
                        <div className="no-class">
                            <Row style={{ textAlign: "center" }} className="justify-content-center">
                                <h1 className="text-primary mb-4"><i className="fa fa-chalkboard-teacher fa-3x" /></h1>
                                <h1 className="fw-bold text-primary">Master Class</h1>
                                <h3>There is not live in this moment</h3>
                            </Row>
                        </div>
                }
            </Container>
        </div>
    )
}

export default withNamespaces()(Masterclass);
