import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Simple bar
import SimpleBar from "simplebar-react";

import SidebarContent from "./SidebarContent";

const Sidebar = (props) => {
    return (
        <>
            <div className="vertical-menu">
                <div data-simplebar className="h-100">
                    <SimpleBar style={{ maxHeight: "100%" }}>
                        <SidebarContent />
                    </SimpleBar>
                </div>

            </div>
        </>
    )
}

export default withRouter(Sidebar);
